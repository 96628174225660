/* global attachEvent */

// *******************************************************************************
// Close Challenge Popup
// *******************************************************************************

attachEvent('.challenge-popup__close', 'click', (ev) => {
  const parentEle = ev.target.closest('.challenge-popup');
  parentEle.classList.add('is-hidden');
  ev.preventDefault();
});
